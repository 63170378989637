@use 'Color.scss';

.signature-container {
  width: 500px;
  height: 100%;
}


.signature {
  width: 100%;

  path {
    animation: sign 2s ease-in forwards;
    animation-delay: 2s;
    stroke: Color.$primary;
    stroke-dasharray: 2083;
    stroke-dashoffset: 2083;
    stroke-width: 5px;
  }

  @keyframes sign{
    to{
        stroke-dashoffset: 0;
    }
  }
}
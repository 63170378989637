@use 'Color.scss';

.intro-container {
  margin-bottom: 200px;
  
  &__name-highlight {
    color: Color.$primary;
  }

  &--about-me {
    display: flex;
    justify-content: space-between;
  }

  &__my-story {
    padding-right: 80px;

    a {
      color: Color.$primary;
      text-decoration: none;
    }
  }

  &__my-image{
    background-image: url(../assets/me.jpg);
    background-position: center;
    background-size: 200px;
    border: 4px solid Color.$primary;
    border-radius: 200px;
    display: flex;

    &:hover{
      background-image: url(../assets/me3.jpg);
    }
  }
}
.works-container {
  margin-bottom: 200px;
  
  .work-sample {
    margin: 36px 0;
  }

  .work-sample:nth-child(even) {
    flex-direction: row-reverse;
  }
}
@use './Color.scss';

.appbar {
  border-bottom: 1px solid #000000;

  &__title {
    cursor: pointer;

    &--highlight {
      color: Color.$primary;
    }
  }

  &__toolbar {
    justify-content: space-between;
  }

  &__nav {
    display: flex;

    p {
      margin: 0 24px;
    }
  }

  &__socials {
    display: flex;

    svg {
      margin: 0 4px;
    }
  }
}